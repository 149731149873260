import React, { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-7 w-7 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fbbd69"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}


const CommonQuestion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <section className="tv-section8">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Some common TV questions</h2>
                    <div className="max-width1000">
                        <Fragment>
                            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(1)}>
                                    Can I add other channels?
                                </AccordionHeader>
                                <AccordionBody>
                                    Yes, for sure. You can pretty much add anything you want to the Local News package. Also if you want to add a channel we currently do not have in our packages let us know and we will see if we can get it for you. 
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(2)}>
                                    Is there DVR?
                                </AccordionHeader>
                                <AccordionBody>
                                    Yup! DVR is available. Add increments of 50 hours of recording time for only $5/month. You can record unlimited channels at the same time with all your recordings saved for 6 months. <p>The coolest thing about the DVR is that all your recordings are available on all your devices.</p>
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(3)}>
                                    Can I adjust my packages?
                                </AccordionHeader>
                                <AccordionBody>
                                    Anytime. Just shoot us a text and we can customize it for you.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(4)}>
                                    How many devices can watch TV at once?
                                </AccordionHeader>
                                <AccordionBody>
                                    You can have up to 15 unique devices connected to the service, with 5 devices all watching at once. 
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(5)}>
                                    Do you guys send me a TV box or can I just use my own?
                                </AccordionHeader>
                                <AccordionBody>
                                    With Purple Cow TV you can use your existing streaming devices including any Apple, Android, Google or firestick device and soon just in your computer browser. We do not provide a TV box or any other streaming boxes. If you currently do not have one we recommend going with an Amazon Firestick. Solid device and only costs around $50.  
                                 </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(6)}>
                                    What is the full list of approved devices?
                                </AccordionHeader>
                                <AccordionBody>
                                <p><strong>Apple TV</strong></p>
                                <li>tvOS 15 or later</li><br></br>
                                <strong>iOS Mobile</strong>
                                <li>iOS 15 or later</li><br></br>
                                <strong>Android Mobile</strong>
                                <li>7.0 or later</li><br></br>
                                <strong>Android TV</strong>
                                <li>Minimum OS supported is Android/Google TV 9.0 or higher (do not support android mobile release on STB)</li><br></br>
                                <strong>Google TV</strong>
                                <li>All versions</li><br></br>
                                <strong>Fire TV</strong>
                                <li>Fire TV (Gen 1) - AFTB (released April 12, 2014))</li>
                                <li>Fire TV Stick (Gen 1) - AFTM - (released Nov 19, 2014)</li>
                                <li>Fire TV (Gen 2) - AFTS - (released Sep 29, 2015)</li>
                                <li>Fire TV Stick (Gen 2) - AFTT (released Oct 20, 2016)</li>
                                <li>Fire TV Edition - Element 4K (2017) - AFTRS</li>
                                 </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 7} icon={<Icon id={7} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(7)}>
                                    What is the complete channel list and guide?
                                </AccordionHeader>
                                <AccordionBody>
                                    <p>A complete channel guide: <a href="https://purplecowinternet.com/tv/channel-guide/" style={{color: "purple"}}>CHANNEL GUIDE</a></p>
                                 </AccordionBody>
                            </Accordion>
                        </Fragment>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CommonQuestion
